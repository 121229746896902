/**
 * Role auth levels:
 *
 * su = 100
 * admin = 60
 * storeAdmin = 40
 * manager = 25
 * associate = 10
 *
 */
// NB: temporarily redirecting all but TNT and dev users to get-it
const _rules = {
  'customize-it': usrlvl => usrlvl >= 0,
  'book-it': usrlvl => usrlvl >= 0,

  // '/': usrlvl => (usrlvl < 9 ? { path: '/shop/book-it' } : true),
  '/myaccount/ecertificates': usrlvl =>
    usrlvl >= 26 ? true : { name: 'myaccount' },

  'admin.access': usrlvl => usrlvl > 30,
  // role selectors
  'is.su': usrlvl => usrlvl === 100,
  'is.admin': usrlvl => usrlvl === 60,
  'is.storeAdmin': usrlvl => usrlvl === 40,
  'is.manager': usrlvl => usrlvl === 25,
  'is.associate': usrlvl => usrlvl === 15,
  'is.dealer': usrlvl => usrlvl === 10,

  // 'cc.only': usrlvl => usrlvl < 7,
  // 'payment.methods': (usrlvl) => usrlvl < 10 ? ['cc', 'credit'] : ['cc', 'po', 'credit']
  // 'payment.methods': (usrlvl) => usrlvl < 25 ? ['cc', 'po'] : ['cc', 'po', 'credit']
  'payment.methods': (usrlvl) => usrlvl < 10 ? ['cc', 'credit'] : ['cc', 'po', 'credit']
}

export default _rules
