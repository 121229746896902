import _ from "lodash/fp";
import constants from "./common-constants";
import regions from "./regions";

const localConstants = {
  clientRebate: 7, // percent

  cardTypeMap: {
    V: "VISA",
    v: "VISA",
    M: "MasterCard",
    m: "MasterCard",
    AX: "American Express",
  },

  userConfig: {
    checkout: {
      setCreditContrib: false,
    },
  },
  siteConfig: {
    brand: "Honda",
    siteName: "HondaGear",
    selfRegister: false,
    noApproval: false,
    customerTypeFields: [
      { name: "dealer", type: "string", required: false },
      { name: "dealerCode", type: "string", required: false },
    ],
    customizeItemDefaultValue: 3,
    quoteOnHeavyOrders: true,
    deferShippingMessage:
      "Due to the weight of your order we will contact you with final shipping cost later. A flat rate of $200 will be added during checkout.",
  },
  regionConfig: {
    regions,
    userDefined: false,
  },

  test_alert: {
    en: `<p style="color: red">This is a test of the <strong>constant</strong> message</p>`,
    fr: `<p style="color: red">This is a test of the <strong>fr constant</strong> message</p>`,
  },

  programAgreement: {
    en: `<div>
        <p>Buy Together & Save Together!</p>
        <p>Please read before proceeding</p>
        <ul>
          <li>For this Book it Program, we are proud to bring you the very best in custom retail styled products.</li>
          <li>This section works as a group buy; the more you buy together, the lower the price for everyone!</li>
          <li>Each product has a live tracker to show the current group buy quantity, current price, and quantity to reach the next price break.</li>
          <li>Orders will be collected for a 3 week period. Please refer to clock on the next page to see time remaining. Your order must be in before closing time.</li>
        </ul>
        <p>Disclaimer:</p>
        <ul>
          <li>Minimum Book It Quantity must be reached as a group to proceed with production. If minimum is not met, you will be notified at closing time.</li>
          <li>Products within the Book it Program are a limited time opportunity, and will only be available during the booking period.</li>
          <li>All orders are final at closing.</li>
          <li>You will be billed at the final price break at closing.</li>
          <li>Production and shipping time can be up to 6 months.</li>
        </ul>
      </div>`,
    fr: `<div>
    <p>Achetez ensemble et économisez ensemble!</p>
    <p>Veuillez lire ce qui suit avant de continuer.</p>
    <ul>
      <li>Dans le cadre du programme Réservez, nous sommes fiers de vous offrir ce qui se fait de mieux en matière de produits stylisés personnalisés vendus au détail.</li>
      <li>Cette section du site est une section d’achat en groupe. Plus vous achetez ensemble, plus le prix baisse pour tous!</li>
      <li>Chaque produit dispose d’un indicateur en temps réel affichant la quantité commandée par le groupe jusqu’à présent, le prix actuel et la quantité à atteindre pour obtenir le prix réduit suivant.</li>
      <li>Les commandes sont recueillies pendant une période de trois semaines. Veuillez vous reporter à l’horloge à la page suivante pour voir le temps qui reste à la période. À la fin de ces trois semaines, votre commande sera automatiquement close et traitée.</li>
    </ul>
    <p>Conditions</p>
    <ul>
      <li>La quantité minimale établie par le programme Réservez doit être atteinte par le groupe pour que la commande passe en production. Vous recevrez un avis si la quantité minimale n’a pas été atteinte à la clôture de la commande.</li>
      <li>Les produits du programme Réservez sont offerts pour une durée limitée et seulement pendant la période de réservation.</li>
      <li>Toutes les commandes sont considérées fermes dès la clôture de la commande.</li>
      <li>Votre facture sera établie au dernier prix réduit au moment de la clôture de la commande.</li>
      <li>La production et l’expédition peuvent prendre jusqu’à six mois.</li>
    </ul>
  </div>`,
  },
  programAgreement_discounts: {
    en: `<div>
    <p>Please read before proceeding</p>
    <ul>
      <li><strong>NEW DISCOUNT STRUCTURE!</strong> Volume discounts will be applied based on your entire order subtotal upon invoicing.</li>
      <li><strong>DISCOUNTS WILL BE APPLIED UPON INVOICING BASED ON THE BELOW STRUCTURE:</strong>
        <ul>
          <li><strong>SUBTOTAL $1,000 - $2,500, RECEIVE 2.5% DISCOUNT</strong></li>
          <li><strong>SUBTOTAL $2,501 - $5,000, RECEIVE 5% DISCOUNT</strong></li>
          <li><strong>SUBTOTAL $5,001 - $7,500, RECEIVE 7.5% DISCOUNT</strong></li>
          <li><strong>SUBTOTAL $7,501 PLUS, RECEIVE 10% DISCOUNT</strong></li>
        </ul>
      </li>
      <li><strong>Discounts are calculated on entire order subtotal cart before shipping & taxes and will be applied upon invoicing. This discount will not be visible at check out.</strong></li>
      <li>Orders will be collected for a 2-week period. Please refer to clock on the next page to see time remaining. Your order must be in before closing time.</li>
    </ul>
    <p>Disclaimer:</p>
    <ul>
      <li>Products within the Book it Program are a limited time opportunity and will only be available during the booking period.</li>
      <li>All orders are final at closing.</li>
      <li>Production and shipping time can be up to 6 months.</li>
    </ul>
  </div>`,
    fr: `<div>
    <p>Veuillez lire ce qui suit avant de continuer.</p>
    <ul>
      <li><strong>NOUVELLE STRUCTURE DE REMISE !</strong> Les ristournes seront appliquées sur la base du total partiel de votre commande au moment de la facturation.</li>
      <li><strong>LES REMISES SERONT APPLIQUÉES LORS DE LA FACTURATION SUR LA BASE DE LA STRUCTURE CI-DESSOUS :</strong>
        <ul>
          <li><strong>SOUS-TOTAL DE 1 000 $ À 2 500 $, OBTENEZ UNE REMISE DE 2,5 %</strong></li>
          <li><strong>SOUS-TOTAL DE 2 501 $ À 5 000 $, OBTENEZ UNE REMISE DE 5 %</strong></li>
          <li><strong>SOUS-TOTAL DE 5 001 $ À 7 500 $, OBTENEZ UNE REMISE DE 7,5 %</strong></li>
          <li><strong>SOUS-TOTAL DE 7 501 $ ET PLUS, OBTENEZ UNE REMISE DE 10 %</strong></li>
        </ul>
      </li>
      <li><strong>Les remises sont calculées sur le total partiel de la commande totale, avant les frais d’envoi et les taxes, et seront appliquées lors de la facturation. Cette remise ne sera pas visible au moment du paiement.</strong></li>
      <li>Les commandes sont recueillies pendant une période de trois semaines. Veuillez vous reporter à l’horloge à la page suivante pour voir le temps qui reste à la période. À la fin de ces trois semaines, votre commande sera automatiquement close et traitée.</li>
    </ul>
    <p>Conditions</p>
    <ul>
      <li>Les produits du programme Réservez sont offerts pour une durée limitée et seulement pendant la période de réservation.</li>
      <li>Toutes les commandes sont considérées fermes dès la clôture de la commande.</li>
      <li>La production et l’expédition peuvent prendre jusqu’à six mois.</li>
    </ul>
  </div>`,
  },
  orderAgreement: {
    en: `<div>
    <p>Product ordered through Book it Program are considered final sale, and by proceeding I acknowledge that I am responsible to full payment based on the quantities ordered.</p>
    <p>Billing will be at the final price break at closing based on the quantity ordered as a group. I will be invoiced at the time of shipping, which can be up to 6 months. </p>
    <p><strong>Click on Continue to proceed with check out.</strong></p>
    </div>`,
    fr: `<div>
    <p>Toutes les commandes sont considérées fermes dès la clôture de la commande.</p>
    <p>Votre facture sera établie au dernier prix réduit au moment de la clôture de la commande.</p>
    <p><strong>Cliquer sur continuer pour continuer.</strong></p>
    </div>`,
  },
  orderAgreement_discounts: {
    en: `<div>
    <ul>
        <li><strong>NEW DISCOUNT STRUCTURE!</strong> Volume discounts will be applied based on your entire order subtotal upon invoicing.</li>
        <li><strong>DISCOUNTS WILL BE APPLIED UPON INVOICING BASED ON THE BELOW STRUCTURE:</strong>
          <ul>
            <li><strong>SUBTOTAL $1,000 - $2,500, RECEIVE 2.5% DISCOUNT</strong></li>
            <li><strong>SUBTOTAL $2,501 - $5,000, RECEIVE 5% DISCOUNT</strong></li>
            <li><strong>SUBTOTAL $5,001 - $7,500, RECEIVE 7.5% DISCOUNT</strong></li>
            <li><strong>SUBTOTAL $7,501 PLUS, RECEIVE 10% DISCOUNT</strong></li>
          </ul>
        </li>
        <li><strong>Discounts are calculated on entire order subtotal cart before shipping & taxes and will be applied upon invoicing. This discount will not be visible at check out.</strong></li>
    </ul>
    <p>Product ordered through Book it Program are considered final sale, and by proceeding I acknowledge that I am responsible to full payment based on the quantities ordered.</p>
    <p><strong>Click on Continue to proceed with check out.</strong></p>
</div>`,
    fr: `<div>
    <ul>
        <li><strong>NOUVELLE STRUCTURE DE REMISE !</strong> Les ristournes seront appliquées sur la base du total partiel de votre commande au moment de la facturation.</li>
        <li><strong>LES REMISES SERONT APPLIQUÉES LORS DE LA FACTURATION SUR LA BASE DE LA STRUCTURE CI-DESSOUS :</strong>
          <ul>
	        <li><strong>SOUS-TOTAL DE 1 000 $ À 2 500 $, OBTENEZ UNE REMISE DE 2,5 %</strong></li>
	        <li><strong>SOUS-TOTAL DE 2 501 $ À 5 000 $, OBTENEZ UNE REMISE DE 5 %</strong></li>
	        <li><strong>SOUS-TOTAL DE 5 001 $ À 7 500 $, OBTENEZ UNE REMISE DE 7,5 %</strong></li>
	        <li><strong>SOUS-TOTAL DE 7 501 $ ET PLUS, OBTENEZ UNE REMISE DE 10 %</strong></li>
          </ul>
        </li>
        <li><strong>Les remises sont calculées sur le total partiel de la commande totale, avant les frais d’envoi et les taxes, et seront appliquées lors de la facturation. Cette remise ne sera pas visible au moment du paiement.</strong></li>
    </ul>
	<p>Toutes les commandes sont considérées fermes dès la clôture de la commande.</p>
	<p><strong>Cliquer sur continuer pour continuer.</strong></p>
</div>`,
  },
  customAgreement: {
    en: `<div>
    <p>Buy More, Save More!</p>
    <p>Please read before proceeding:</p>
    <ul>
      <li>This section allows you to customize items by selecting logos and their placement.  </li>
      <li>Add your dealer logo to any item!</li>
      <li>Each item has a minimum order quantity.</li>
    </ul> 
    <p>Disclaimer:</p>
    <ul>
      <li>Customize It orders are made to order and can take up to 2 – 3 weeks for final delivery.</li>
      <li>Rush orders can be accommodated and will be dealt with on a case by case basis. Please specify rush needs in Special Instruction box on product page, or email us directly at <a href="mailto:honda@tntworld.com">honda@tntworld.com</a></li>
      <li>The online system is used as a guide only. You will be emailed a final proof for approval before production. </li>
      <li>Your CE CSR rep will provide an estimated delivery date upon proof approval.</li>
      <li>Customize It orders are final sale. Credit will only be provided for manufacture defect or incorrect logo placement as per approved proof.</li>
      <li>You will be billed at time of shipping.</li>
      <li>Lower priced items are subject to plus or minus 5% of the quantity ordered. You will be billed for final quantity shipped.</li>
    </ul>
    </div>`,
    fr: `<div>
    <p>Achetez plus, économisez plus!</p>
    <p>Veuillez lire ce qui suit avant de continuer.</p>
    <ul>
      <li>Cette section vous permet de personnaliser des articles en sélectionnant les logos désirés et en choisissant leur emplacement.  </li>
      <li>Ajoutez votre logo de concessionnaire à n’importe quel article!</li>
      <li>Une quantité minimale s’applique à chaque article commandé. Plus la quantité commandée est élevée, plus les prix sont bas.</li>
    </ul>
    <p>Conditions :</p>
    <ul>
      <li>Les articles Customize It sont faits sur commandes et sont livrés en deux ou trois semaines.</li>
      <li>Les commandes urgentes sont possibles et seront jugées au cas par cas. Veuillez spécifier vos besoins urgents dans l’encadré « Instructions spéciales » sur la page du produit, ou écrivez-nous directement par courriel à l’adresse <a href="mailto:honda@tntworld.com">honda@tntworld.com</a>.</li>
      <li>Le système en ligne ne sert que de repère. Nous vous enverrons une épreuve finale par courriel pour approbation avant d’amorcer la production.</li>
      <li>Après l’approbation des épreuves, votre représentant du service à la clientèle CE vous fournira une date de livraison estimée.</li>
      <li>Les commandes Customize It sont finales. Elles seront remboursées seulement en cas de défaut de fabrication ou d’emplacement inexact du logo par rapport aux épreuves approuvées.</li>
      <li>Vous recevrez votre facture au moment de la livraison.</li>
      <li>Une variation de plus ou moins 5 % s’applique à la quantité commandée d’articles à prix réduit. Votre facture sera établie en fonction de la quantité réelle d’articles livrée.</li>
    </ul>
    </div>`,
  },
  receiptBlurb: {
    en: `<div class="small-12 large-3 columns disclaimer">
      <p>As fulfilled and processed by<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive, <br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Term & Conditions:</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>PLEASE NOTE THAT ALL FIGURES ARE IN CANADIAN DOLLARS</li>
      <li>Local taxes will be invoiced based on shipping address.</li>
      <li>Duty, taxes and brokerage are the responsibility of the customer/purchaser for any applicable International shipments (FOB Mississauga, ON, Canada).</li>
      <li>This represents both your order confirmation and Invoice. You will not receive any further documentation regarding your order.</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
    fr: `<div class="small-12 large-3 columns disclaimer">
      <p>Telle que remplie et traitée par<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive, <br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Modalités :</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>VEUILLEZ NOTER QUE TOUS LES MONTANTS SONT INDIQUÉS EN DOLLARS CANADIENS</li>
      <li>Les taxes locales seront facturées en fonction de l’adresse de livraison.</li>
      <li>Les droits, taxes et frais de courtage sont la responsabilité du client ou de l’acheteur pour tout envoi international applicable (FOB Mississauga, Ontario, Canada).</li>
      <li>La présente constitue à la fois votre confirmation de commande et votre facture. Vous ne recevrez aucun autre document relativement  à votre commande.</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
  },
  programReceiptBlurb: {
    en: `<div class="small-12 large-3 columns disclaimer">
      <p>As fulfilled and processed by<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive, <br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Term & Conditions:</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>PLEASE NOTE THAT ALL FIGURES ARE IN CANADIAN DOLLARS</li>
      <li>Local taxes will be invoiced based on shipping address.</li>
      <li>Duty, taxes and brokerage are the responsibility of the customer/purchaser for any applicable International shipments (FOB Mississauga, ON, Canada).</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
    fr: `<div class="small-12 large-3 columns disclaimer">
      <p>Telle que remplie et traitée par<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive, <br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Modalités :</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>VEUILLEZ NOTER QUE TOUS LES MONTANTS SONT INDIQUÉS EN DOLLARS CANADIENS</li>
      <li>Les taxes locales seront facturées en fonction de l’adresse de livraison.</li>
      <li>Les droits, taxes et frais de courtage sont la responsabilité du client ou de l’acheteur pour tout envoi international applicable (FOB Mississauga, Ontario, Canada).</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
  },
  customReceiptBlurb: {
    en: `<div class="small-12 large-3 columns disclaimer">
      <p>As fulfilled and processed by<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive, <br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Term & Conditions:</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>PLEASE NOTE THAT ALL FIGURES ARE IN CANADIAN DOLLARS</li>
      <li>Local taxes will be invoiced based on shipping address.</li>
      <li>Duty, taxes and brokerage are the responsibility of the customer/purchaser for any applicable International shipments (FOB Mississauga, ON, Canada).</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
    fr: `<div class="small-12 large-3 columns disclaimer">
      <p>Telle que remplie et traitée par<br />
      The Next Trend Designs Inc.<br />
      1102 Aerowood Drive, <br />
      Mississauga, ON, L4W 1Y5</p>
      </div>
      <div class="small-12 large-9 columns terms">
      <p style="margin-bottom: 0;"><strong>Modalités :</strong></p>
      <ol style="margin-bottom: 0.3rem;">
      <li>VEUILLEZ NOTER QUE TOUS LES MONTANTS SONT INDIQUÉS EN DOLLARS CANADIENS</li>
      <li>Les taxes locales seront facturées en fonction de l’adresse de livraison.</li>
      <li>Les droits, taxes et frais de courtage sont la responsabilité du client ou de l’acheteur pour tout envoi international applicable (FOB Mississauga, Ontario, Canada).</li>
      </ol>
      <p>HST Reg # 13961 5389 RT0001</p>
      </div>`,
  },
  checkoutModal: {
    en: `<div style="padding:1rem;">
    <p><strong>NEW DISCOUNT STRUCTURE!</strong> Volume discounts will be applied based on your entire order subtotal upon invoicing.</p>
    <p><strong>DISCOUNTS WILL BE APPLIED UPON INVOICING BASED ON THE BELOW STRUCTURE:</strong>
      <ul style="padding:0 2rem;">
        <li><strong>SUBTOTAL $1,000 - $2,500, RECEIVE 2.5% DISCOUNT</strong></li>
        <li><strong>SUBTOTAL $2,501 - $5,000, RECEIVE 5% DISCOUNT</strong></li>
        <li><strong>SUBTOTAL $5,001 - $7,500, RECEIVE 7.5% DISCOUNT</strong></li>
        <li><strong>SUBTOTAL $7,501 PLUS, RECEIVE 10% DISCOUNT</strong></li>
      </ul>
    </p>
    <p><strong>Discounts are calculated on entire order subtotal cart before shipping & taxes and will be applied upon invoicing. This discount will not be visible at check out.</strong></p>
</div>`,
    fr: `<div style="padding:1rem;">
    <p><strong>NOUVELLE STRUCTURE DE REMISE !</strong> Les ristournes seront appliquées sur la base du total partiel de votre commande au moment de la facturation.</p>
    <p><strong>LES REMISES SERONT APPLIQUÉES LORS DE LA FACTURATION SUR LA BASE DE LA STRUCTURE CI-DESSOUS :</strong>
      <ul style="padding:0 2rem;">
        <li><strong>SOUS-TOTAL DE 1 000 $ À 2 500 $, OBTENEZ UNE REMISE DE 2,5 %</strong></li>
        <li><strong>SOUS-TOTAL DE 2 501 $ À 5 000 $, OBTENEZ UNE REMISE DE 5 %</strong></li>
        <li><strong>SOUS-TOTAL DE 5 001 $ À 7 500 $, OBTENEZ UNE REMISE DE 7,5 %</strong></li>
        <li><strong>SOUS-TOTAL DE 7 501 $ ET PLUS, OBTENEZ UNE REMISE DE 10 %</strong></li>
      </ul>
    </p>
    <p><strong>Les remises sont calculées sur le total partiel de la commande totale, avant les frais d’envoi et les taxes, et seront appliquées lors de la facturation. Cette remise ne sera pas visible au moment du paiement.</strong></p>
</div>`,
  },

  locale: {
    en: {
      currency: {
        symbol: "$",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      },
      upsServiceTypeMap: {
        70: "UPS Access Point Economy", // Canadian domestic shipments
        12: "UPS 3 Day Select", // Shipments originating in Canada
        "08": "UPS Worldwide Expedited International", // Shipments originating in Canada
        "01": "UPS Express", // Canadian domestic shipments
        "02": "UPS Expedited", // Canadian domestic shipments
        13: "UPS Express Saver", // Canadian domestic shipments
        14: "UPS Express Early", // Canadian domestic shipments
        65: "UPS Express Saver", // Shipment originating in Canada (Domestic & Int'l)
        11: "UPS Standard", // Shipments originating in Canada (Domestic and Int’l)
        "07": "UPS Worldwide Express", // International shipments originating in Canada
        54: "UPS Worldwide Express Plus", // International shipments originating in Canada
        TBD: "Order exceeds max weight for single package, get an estimate and update the order.",
      },
    },
    fr: {
      currency: {
        symbol: "$",
        decimal: ",",
        thousand: " ",
        precision: 2,
        format: "%v %s",
      },
      upsServiceTypeMap: {
        70: "UPS Access Point Economy", // Canadian domestic shipments
        12: "UPS 3 Day Select", // Shipments originating in Canada
        "08": "UPS Worldwide Expedited International", // Shipments originating in Canada
        "01": "UPS Express", // Canadian domestic shipments
        "02": "UPS Expedited", // Canadian domestic shipments
        13: "UPS Express Saver", // Canadian domestic shipments
        14: "UPS Express Early", // Canadian domestic shipments
        65: "UPS Express Saver", // Shipment originating in Canada (Domestic & Int'l)
        11: "UPS Standard", // Shipments originating in Canada (Domestic and Int’l)
        "07": "UPS Worldwide Express", // International shipments originating in Canada
        54: "UPS Worldwide Express Plus", // International shipments originating in Canada
        TBD: "Order exceeds max weight for single package, get an estimate and update the order.",
      },
    },
  },
};

export default _.assign(constants, localConstants);
